import { Component, Input } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';
import { PropertyViewModel } from '../../view-model/property-view-model';


@Component({
   selector: 'asc-form-field-bool-dropdown',
   templateUrl: './form-field-bool-dropdown.component.html',
   styleUrls: ['./form-field-bool-dropdown.component.scss'],
   viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
   inputs: ["property"]
})
export class FormFieldBoolDropdownComponent {
   @Input('property')
   public vm: PropertyViewModel | undefined;

   public items: { key: boolean, label: string }[] = [
      { key: false, label: 'Nein' },
      { key: true, label: 'Ja' }
   ];
}
