@if (vm)
{
<!-- <mat-form-field> -->
   <!-- <mat-label>{{vm.property.displayName}}</mat-label> -->
   <!-- <asc-form-field-tooltip [property]="vm" /> -->
   <!-- <mat-radio-group [formControl]="vm.formControl" [required]="vm.property.isRequired">
      @for (selectionItem of vm.property.itemsSource; track $index) {
      <mat-option [value]="selectionItem.key">{{selectionItem.label}}</mat-option>
      <mat-radio-button [value]="selectionItem.key">{{selectionItem.label}}</mat-radio-button>
      }
   </mat-radio-group> -->
   <!-- <mat-error>{{vm.getErrorMessage()}}</mat-error> -->
<!-- </mat-form-field> -->
}