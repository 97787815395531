import { Component, Input } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';
import { PropertyViewModel } from '../../view-model/property-view-model';
import { ValueItem } from '../../model/value-item';
import { MatChipInputEvent } from '@angular/material/chips';


@Component({
   selector: 'asc-form-field-valueitem-dropdown-multi-selection',
   templateUrl: './form-field-valueitem-dropdown-multi-selection.component.html',
   styleUrls: ['./form-field-valueitem-dropdown-multi-selection.component.scss'],
   viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
   inputs: ["property"]
})
export class FormFieldValueItemDropdownMultiSelectionComponent {
   @Input('property')
   public vm: PropertyViewModel | undefined;

   public add(e: MatChipInputEvent) {
      if (!this.vm?.property) { return; }

      if (!Array.isArray(this.vm.property.value)) {
         this.vm.property.value = [];
      }

      // e.chipInput.
      // this.vm.property.value.push(item);
   }

   public remove(item: ValueItem) {
      if (!this.vm?.property) { return; }

      const index = this.vm.property.value.indexOf(item);
      if (index > -1) {
         this.vm.property.value.slice(index, 1);
      }
   }

   public displayFn(item: ValueItem): string {
      return item && item.label ? item.label : '';
   }
}
