import { Component, Input } from '@angular/core';
import { PropertyViewModel } from '../../view-model/property-view-model';


@Component({
   selector: 'asc-form-field-tooltip',
   templateUrl: './form-field-tooltip.component.html',
   styleUrls: ['./form-field-tooltip.component.scss'],
   inputs: ["property"]
})
export class FormFieldTooltipComponent {
   @Input('property')
   public vm: PropertyViewModel | undefined;
}
