import { Component, Input } from '@angular/core';
import { Icon } from '../../model/icon';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';


@Component({
   selector: 'asc-form-icon',
   templateUrl: './form-icon.component.html',
   styleUrls: ['./form-icon.component.scss'],
})
export class FormIconComponent {

   @Input()
   public icon?: Icon

   @Input()
   public size?: '16' | '24' = '16';

   constructor(private sanitizer: DomSanitizer) { }

   public get imageSource(): string | undefined {
      return (this.icon?.imageExtension !== 'svg' && this.icon?.content) ? `data:image/${this.icon.imageExtension};base64,${this.icon.content}` : undefined;
   }

   public get imageSvg(): SafeHtml | undefined {
      if (this.icon?.imageExtension === 'svg' && this.icon?.content) {
         const html = atob(this.icon.content);
         const parser = new DOMParser();
         const doc = parser.parseFromString(html, "image/svg+xml");

         // Clean-up svg attributes (set by css)
         const svg = doc.documentElement;
         svg.attributes.removeNamedItem('height');
         svg.attributes.removeNamedItem('width');
         svg.attributes.removeNamedItem('fill');
         return this.sanitizer.bypassSecurityTrustHtml(svg.outerHTML);
      }
      else {
         return undefined;
      }
   }
}
