import { Component, Input } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';
import { PropertyViewModel } from '../../view-model/property-view-model';


@Component({
   selector: 'asc-form-field-radio-buttons',
   templateUrl: './form-field-radio-buttons.component.html',
   styleUrls: ['./form-field-radio-buttons.component.scss'],
   viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
   inputs: ["property"]
})
export class FormFieldRadioButtonsComponent {
   @Input('property')
   public vm: PropertyViewModel | undefined;
}
