import { Component, Input } from '@angular/core';
import { FormSectionViewModel } from '../../view-model/form-section-view-model';


@Component({
   selector: 'asc-form-section',
   templateUrl: './form-section.component.html',
   styleUrls: ['./form-section.component.scss'],
})
export class FormSectionComponent {

   @Input('section')
   public vm?: FormSectionViewModel;

   @Input('displayMode')
   public displayMode?: 'edit' | 'read-only' | undefined;

   @Input('showEmptyProperties')
   public showEmptyProperties = true;
}
