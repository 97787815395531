@if (vm)
{
<mat-form-field>
   <mat-label>{{vm.property.displayName}}</mat-label>
   <asc-form-field-tooltip [property]="vm" />
   <mat-chip-grid #chipGrid>
      @for (selectedItem of (vm.property.value || []); track $index) {
      <mat-chip-row (removed)="remove(selectedItem)">
         {{selectedItem.label}}
         @if (!selectedItem.isMandatory)
         {
         <button matChipRemove>
            <mat-icon>cancel</mat-icon>
         </button>
         }
      </mat-chip-row>
      }
   </mat-chip-grid>

   <!-- [matChipInputSeparatorKeyCodes]="separatorKeysCodes" -->
   <!-- (optionSelected)="selected($event)" -->
   <!-- [matChipInputFor]="chipGrid" -->
   <!-- (matChipInputTokenEnd)="add($event)" -->
   <input [placeholder]="vm.property.sampleData || ''" 
      [matAutocomplete]="auto" [matChipInputFor]="chipGrid" />

      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
      @for (selectionItem of vm.property.itemsSource; track $index) {
      <mat-option [value]="selectionItem.key">{{selectionItem.label}}</mat-option>
      }
   </mat-autocomplete>

   <mat-error>{{vm.getErrorMessage()}}</mat-error>
</mat-form-field>
}