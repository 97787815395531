import { Component, Input } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';
import { PropertyViewModel } from '../../view-model/property-view-model';


@Component({
   selector: 'asc-form-field-string-text-box',
   templateUrl: './form-field-string-text-box.component.html',
   styleUrls: ['./form-field-string-text-box.component.scss'],
   viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class FormFieldStringTextBoxComponent {
   @Input('property')
   public vm: PropertyViewModel | undefined;
}
