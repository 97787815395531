import { Observable, Subject } from "rxjs";
import { FormSectionViewModel } from "../view-model/form-section-view-model";
import { FormViewModel } from "../view-model/form-view-model";

export class FormNavigationService {

    public selectedIndex: number = -1;
    private _selectedIndexChanged = new Subject<number>();

    constructor(private form: FormViewModel) { }

    public get selectedSection(): FormSectionViewModel | undefined {
        return this.form.sections.find(s => s.isSelected);
    }

    public get selectedIndexChanged(): Observable<number> {
        return this._selectedIndexChanged;
    }

    public get canSelectPreviousSection(): boolean {
        return this.selectedIndex > 0;
    }

    public get canSelectNextSection(): boolean {
        return this.selectedIndex < (this.form.sections.length - 1);
    }

    public selectFirstSection() {
        this.selectSection(0);
    }

    public selectLastSection() {
        this.selectSection(this.form.sections.length - 1);
    }

    public selectNextSection() {
        this.selectSection(this.selectedIndex + 1);
    }

    public selectPreviousSection() {
        this.selectSection(this.selectedIndex - 1);
    }

    public selectSection(index: number) {
        if (index < 0) { index = 0; }
        if (index >= this.form.sections.length) { index = this.form.sections.length - 1; }

        this.selectedIndex = index;
        this.form.selectSection(this.selectedIndex);
        this._selectedIndexChanged.next(this.selectedIndex);
    }
}