@if (vm)
{
<mat-form-field>
   <mat-label>{{vm.property.displayName}}</mat-label>
   <asc-form-field-tooltip [property]="vm" />
   <input matInput type="text" [formControl]="vm.formControl" [placeholder]="vm.property.sampleData || ''"
      [required]="vm.property.isRequired" [matAutocomplete]="auto" (input)="filter($event)">
   <mat-autocomplete requireSelection #auto="matAutocomplete" [displayWith]="displayFn">
      @for (item of filteredItems; track $index) {
      <mat-option [value]="item">{{item.label}}</mat-option>
      }
   </mat-autocomplete>
   <mat-error>{{vm.getErrorMessage()}}</mat-error>
</mat-form-field>
}