import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';
import { PropertyViewModel } from '../../view-model/property-view-model';
import { ValueItem } from '../../model/value-item';


@Component({
   selector: 'asc-form-field-valueitem-dropdown',
   templateUrl: './form-field-valueitem-dropdown.component.html',
   styleUrls: ['./form-field-valueitem-dropdown.component.scss'],
   viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
   inputs: ["property"]
})
export class FormFieldValueItemDropdownComponent implements OnInit {
   @Input('property')
   public vm: PropertyViewModel | undefined;

   // public filteredItems!: Observable<ValueItem[]>;
   public filteredItems!: ValueItem[];

   ngOnInit() {
      this.filteredItems = this.vm!.property.itemsSource.slice();
      // this.filteredItems = this.vm!.formControl.valueChanges.pipe(
      //    startWith(''),
      //    map(value => {
      //       const label = typeof value === 'string' ? value : value?.label;
      //       return label ? this._filter(label as string) : this.vm!.property.itemsSource.slice();
      //    }),
      // );
   }

   public displayFn(item: ValueItem): string {
      return item && item.label ? item.label : '';
   }

   public filter(event: Event): void {
      const label = (<HTMLInputElement>event.target).value;
      const filterValue = label.toLowerCase();
      this.filteredItems = this.vm!.property.itemsSource.filter(item => (item.label || '').toLowerCase().includes(filterValue));
   }

   private _filter(label: string): ValueItem[] {
      const filterValue = label.toLowerCase();

      return this.vm!.property.itemsSource.filter(item => (item.label || '').toLowerCase().includes(filterValue));
   }
}
