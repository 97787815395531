@if (vm)
{
<div class="asc-form-field">
   <div class="asc-field-wrapper">
      <div class="asc-form-field-infix">
         <asc-form-field-tooltip [property]="vm" />
         <mat-slide-toggle [formControl]="vm.formControl">
            <span>{{vm.property.displayName}}</span>
            @if (vm.property.isRequired)
            {
            <span>*</span>
            }
         </mat-slide-toggle>
         <asc-form-field-tooltip [property]="vm" />
      </div>
   </div>
   <div class="asc-field-subscript-wrapper">
      <mat-error>{{vm.getErrorMessage()}}</mat-error>
   </div>
</div>
}