@if (vm)
{
<mat-form-field>
  <mat-label>{{vm.property.displayName}}</mat-label>
  <asc-form-field-tooltip [property]="vm" />
  <input matInput [formControl]="vm.formControl" [matDatepicker]="picker" [required]="vm.property.isRequired">
  <mat-error>{{vm.getErrorMessage()}}</mat-error>
  <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
</mat-form-field>
}