@if (vm)
{
@if (vm.group.icon || vm.group.title)
{
<div class="asc-form-title">
   @if (vm.group.icon)
   {
   <asc-form-icon [icon]="vm.group.icon" size="24" />
   }
   @if (vm.group.title)
   {
   <h3>{{vm.group.title}}</h3>
   }
</div>
}
@if (displayMode === 'edit' && vm.group.description)
{
<p class="asc-form-description">{{vm.group.description}}</p>
}
<div class="asc-form-properties">
   @for (property of vm.properties; track $index)
   {
   @if (displayMode === 'read-only') {
   @if (property.property.controlType !== 'HiddenValue')
   {
   <div class="asc-form-property">
      <label class="asc-property-label">{{property.property.displayName}}</label>
      <div class="asc-property-value" [ngClass]="{'asc-no-value': !property.formControl.value}">
         @switch (property.property.controlType) {
         @case ('DatePicker') {
         {{(property.formControl.value | date) || '-'}}
         }
         @case ('Dropdown') {
         {{property.formControl.value?.Label || '-'}}
         }
         @default {
         {{property.formControl.value || '-'}}
         }
         }
      </div>
   </div>
   }
   }
   @else {
   @switch(property.property.dataType)
   {
   @case ('Bool')
   {
   @switch (property.property.controlType) {
   @case ('CheckBox') {
   <asc-form-field-bool-check-box [property]="property" />
   }
   @case ('Dropdown') {
   <asc-form-field-bool-dropdown [property]="property" />
   }
   @case ('SlideToggle') {
   <asc-form-field-bool-slide-toggle [property]="property" />
   }
   @default {
   <p style="color:rgb(255, 0, 195);">No implementation found for control type '{{property.property.controlType}}'.</p>
   }
   }
   }
   @case ('DateTime')
   {
   <asc-form-field-datetime-datepicker [property]="property" />
   }
   @case ('Object')
   {

   }
   @case ('String')
   {
   @switch (property.property.controlType)
   {
   @case ('HiddenValue')
   {
   <!-- hidden -->
   }
   @case ('TextBox')
   {
   <asc-form-field-string-text-box [property]="property" />
   }
   @case ('TextBoxMultiline')
   {
   <asc-form-field-string-text-box-multiline [property]="property" />
   }
   @default {
   <p style="color:rgb(255, 0, 195);">No implementation found for control type '{{property.property.controlType}}'.</p>
   }
   }
   }
   @case ('ValueItem')
   {
   @switch (property.property.controlType)
   {
   @case ('Dropdown') {
   <asc-form-field-valueitem-dropdown [property]="property"
      [ngClass]="{'asc-control-size-large': property.isControlSizeLarge}" />
   }
   @case ('DrowdownMultiSelection') {
   <asc-form-field-valueitem-dropdown-multi-selection [property]="property"
      [ngClass]="{'asc-control-size-large': property.isControlSizeLarge}" />
   }
   @default
   {
   <p style="color:rgb(255, 0, 195);">No implementation found for control type '{{property.property.controlType}}'.</p>
   }
   }
   }
   @default
   {
   <p style="color:rgb(255, 0, 195);">No implementation found for data type '{{property.property.dataType}}'.</p>
   }
   }


   @switch (property.property.controlType) {
   @case ('RadioButton') {
   <asc-form-field-radio-buttons [property]="property"
      [ngClass]="{'asc-control-size-large': property.isControlSizeLarge}" />
   }
   }
   }
   }
</div>
}